import i18n from 'i18next';
import { makePlugins } from '@skeepers/i18n/i18next';
import { initReactI18next as react } from 'react-i18next';
import config from './config';

const {
    backend, postProcessor, detector, initOptions,
} = makePlugins({
    url: config.api.url,
    template: 'connect',
});

export const init = () => i18n
    .use(backend)
    .use(detector)
    .use(postProcessor)
    .use(react)
    .init({
        ...initOptions,
        react: {
            useSuspense: false,
        },
    });

export const getLanguageCode = () => i18n.language;
