import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from './config';

export const client = new ApolloClient({
    name: 'connect',
    version: config.version,
    uri: `${config.api.url}graphql`,
    cache: new InMemoryCache(),
    credentials: 'include',
});
