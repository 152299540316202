import { CLIENT_NAMES } from '../utils';

export const GRAPHQL_ERRORS = {
    clientId: 'ERROR_INVALID_CLIENTID',
    clientToken: 'ERROR_GET_JWT_FROM_CLIENT',
    token: 'ERROR_INVALID_TOKEN',
    user: 'ERROR_INVALID_USER',
};

export const VALID_GRAPHQL_ERROR_CODES = Object.values(GRAPHQL_ERRORS);

export const getErrorMessages = (t, extensions = {}) => ({
    [GRAPHQL_ERRORS.clientId]: t('We could not recognize a platform you were redirected from. Please, try again.'),
    [GRAPHQL_ERRORS.clientToken]: t('Please, go back to {{clientName}} website and retry to connect.', { clientName: CLIENT_NAMES[extensions.externalClientId] || 'client' }),
    [GRAPHQL_ERRORS.token]: t('Your authentication failed. Please, try again.'),
    [GRAPHQL_ERRORS.user]: t('Failed to load your user details. Please, try again.'),
});

export const parseGraphqlError = (t, error) => {
    const code = error?.extensions?.code;
    if (code && VALID_GRAPHQL_ERROR_CODES.includes(code)) {
        return getErrorMessages(t, error.extensions)[code];
    }
    return t(error ? error.message : 'unknown error please try again');
};
