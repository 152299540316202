import config from '../config';

const checkUrlContext = (url) => {
    if (!config.ALLOWED_DOMAINS) {
        return url;
    }
    try {
        const domain = (new URL(url)).hostname;
        const allowedDomains = config.ALLOWED_DOMAINS.split(',').map((d) => d.trim().toLowerCase()).filter((d) => !!d);
        if (allowedDomains.find((allowedDomain) => domain.endsWith(allowedDomain))) {
            return url;
        }
    } catch (e) {
        // handle error
    }

    return null;
};

export const useParams = (t) => {
    const query = window.location.search.substring(1);
    const searchParams = new URLSearchParams(query);
    const params = {
        redirectUri: checkUrlContext(searchParams.get('redirectUri')),
        solutionId: searchParams.get('solutionId'),
        clientId: searchParams.get('clientId'),
        authorizationCode: searchParams.get('authorizationCode'),
        mode: searchParams.get('mode') || 'social',
        color: searchParams.get('color') || 'var(--layout-primary-color)',
        companyName: searchParams.get('companyName') !== 'null' ? searchParams.get('companyName') : null,
        logo: searchParams.get('logo') !== 'null' ? checkUrlContext(searchParams.get('logo')) : null,
        backgroundImage: checkUrlContext(searchParams.get('backgroundImage')) || encodeURI(config.default.DEFAULT_BACKGROUND),
        accountType: searchParams.get('accountType') || [],
        strategy: searchParams.get('strategy'),
    };

    return {
        params,
        error: checkUrlContext(searchParams.get('redirectUri')) && (searchParams.has('solutionId') || searchParams.get('clientId')) ? null : t('PARAMS_ERROR_REDIRECT'),
    };
};
