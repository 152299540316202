import firebase from 'firebase/app';
import { useState } from 'preact/hooks';
import { useQuery, useMutation, gql } from '@apollo/client';
import { isWebview, cleanObject, shouldSignDocument } from '../utils';

import { useParams } from './params';
import { useTracking } from './track';
import { useRoute } from './route';
import { getLanguageCode } from '../i18n';
import { getProvider } from '../providers';
import { useUser } from '../contexts/UserContext';

const GET_ME = gql`
  query getMe($languageCode: LanguageCode, $source: String) {
    getMe {
      id
      displayName
      picture
      acceptedDocuments {
        acceptedAt
        document {
          id
          language
          type
          version
        }
      }
      signableDocuments(languageCode: $languageCode, source: $source) {
        id
        language
        type
        version
        isSignable
      }
    }
  }
`;

const LOGIN = gql`
  mutation login($token: String!, $user: UserInput) {
    login(token: $token, user: $user) {
      id
    }
  }
`;

const LOGIN_EXTERNAL = gql`
  mutation loginExternal($clientId: String!, $authorizationCode: String!, $languageCode: LanguageCode, $source: String) {
    loginExternal(clientId: $clientId, authorizationCode: $authorizationCode) {
      id
      email
      displayName
      acceptedDocuments {
        acceptedAt
        document {
          id
          language
          type
          version
        }
      }
      signableDocuments(languageCode: $languageCode, source: $source) {
        id
        language
        type
        version
        isSignable
      }
    }
  }
`;

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const GET_USER_AUTH_PROVIDER_BY_EMAIL = gql`
  query getUserAuthProviderByEmail($email: String!) {
    getUserAuthProviderByEmail(email: $email)
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $url: URL!) {
    resetPassword(email: $email, url: $url)
  }
`;

const getLoginPayload = ({ token, user }) => ({
    mutation: gql`
      mutation login ($token: String!, $user: UserInput) {
        login (token: $token, user: $user) {
          id
        }
      }
    `,
    variables: {
        token,
        user,
    },
});

export const getLogoutPayload = () => ({
    mutation: gql`
        mutation logout {
        logout
      }
    `,
});

export const useAuth = (t) => {
    const { params } = useParams(t);
    const track = useTracking(params.solutionId);
    const redirect = useRoute(t);
    const [loading, setLoading] = useState(false);
    const { user, setUser } = useUser();
    const [source] = useState(params.redirectUri?.includes('backoffice') ? 'bo' : 'platform');
    const [languageCode] = getLanguageCode().split('-');

    const {
        data, loading: gqlLoading, error, refetch,
    } = useQuery(GET_ME, {
        fetchPolicy: 'network-only',
        skip: params.clientId,
        variables: {
            languageCode,
            source,
        },
    });

    if (data) {
        setUser(data.getMe);
    }

    const [loginExternalMutation] = useMutation(LOGIN_EXTERNAL, {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        variables: {
            clientId: params.clientId,
            authorizationCode: params.authorizationCode,
            languageCode,
            source,
        },
    });

    const [logoutMutation] = useMutation(LOGOUT, {
        refetchQueries: [{ query: GET_ME }],
        awaitRefetchQueries: true,
    });

    const [loginMutation] = useMutation(LOGIN);

    const login = ({ token, user: loggedUser }) => loginMutation({
        variables: {
            token,
            user: loggedUser,
        },
    });

    const [sendMailResetPasswordMutation] = useMutation(RESET_PASSWORD);

    const sendMailResetPassword = (email) => sendMailResetPasswordMutation({
        variables: {
            email,
            url: `${window.location.href}${params.redirectUri?.includes('backoffice') ? `&lng=${navigator.language.includes('en') ? 'en' : 'fr'}` : ''}`,
        },
    });

    const loginExternal = async () => {
        try {
            setLoading(true);
            const result = await loginExternalMutation();
            if (result.data?.loginExternal) {
                setUser(result.data.loginExternal);
                shouldSignDocument(result.data.loginExternal, redirect, params.redirectUri);
                return { user: result.data.loginExternal };
            }
            if (result.errors) {
                return { error: result.errors[0] };
            }
        } catch (err) {
            return;
        } finally {
            setLoading(false);
        }
    };

    // Shared sign in logic
    const signIn = async (result) => {
        const extractInfo = async ({ user: signedUser, additionalUserInfo }) => {
            const provider = getProvider(additionalUserInfo.providerId);
            const token = await signedUser.getIdToken();

            return {
                token,
                user: cleanObject(
                    provider.extractDetails({ user: signedUser, additionalUserInfo }),
                ),
            };
        };

        setLoading(true);

        try {
            const redirectionUrl = new URL(params.redirectUri);
            const info = await extractInfo(result);

            if (params.strategy === 'none') {
                console.log('Raw sign in result', result);
                console.log('Extracted payload', info);
                return;
            } else if (params.strategy === 'handoff') {
                redirectionUrl.searchParams.set('handoff', btoa(JSON.stringify(getLoginPayload(info))));
                window.location = redirectionUrl;
            } else {
                const { data: { getMe: connectedUser } } = await login(info).then(refetch);
                track('CONNECT_CONNECT_SUCCESS');
                shouldSignDocument(connectedUser, redirect, params.redirectUri);
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const socialLogin = async (provider) => {
        try {
            if (isWebview(navigator.userAgent)) {
                redirect('/webview');
            } else {
                await firebase.auth().signInWithPopup(provider).then(signIn);
            }
        } catch (err) {
            if (err.email && err.code === 'auth/account-exists-with-different-credential') {
                const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(err.email);

                if (signInMethods.length && !signInMethods.includes(firebase.auth.EmailAuthProvider.PROVIDER_ID)) {
                    return redirect('/already-account', {
                        account_type: signInMethods.toString(),
                        emailUser: err.email,
                    });
                }
            }

            console.error(err);
        }
    };

    /**
   * The `login*****` methods perform calls to Firebase to get a signin result, from which
   * we extract the necessary info to log the user in on our backend, in the `signIn` function.
   * Loading only represents the network request time between the front-end and our backend,
   * request loading between the front-end and Firebase should be handled in another way (for example,
   * local loading state). The `login*****` methods do not catch errors, they should be
   * handled by the caller (i.e. the Login component) if there are any.
   */

    return {
        loading: gqlLoading || loading,
        error,
        user,
        loginExternal,
        async registerEmail (email, password, displayName) {
            await firebase.auth().createUserWithEmailAndPassword(email, password);
            await firebase.auth().currentUser.updateProfile({ displayName });
            track('CONNECT_REGISTER_SUCCESS');
            await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(signIn);
        },
        async loginEmail (email, password) {
            const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);

            if (signInMethods.length && !signInMethods.includes(firebase.auth.EmailAuthProvider.PROVIDER_ID)) {
                return redirect('/already-account', {
                    account_type: signInMethods.toString(),
                    emailUser: email,
                });
            }

            await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(signIn);
        },
        loginGoogle: () => socialLogin(new firebase.auth.GoogleAuthProvider()),
        loginApple: () => socialLogin(new firebase.auth.OAuthProvider('apple.com')),
        loginFacebook: () => socialLogin(new firebase.auth.FacebookAuthProvider()),
        async logout () {
            await firebase.auth().signOut();
            return logoutMutation();
        },
        mailResetPassword: (email) => sendMailResetPassword(email),
        verifyResetPasswordCode: async (code) => firebase
            .auth()
            .verifyPasswordResetCode(code),
        confirmPasswordReset: async (code, newPassword) => firebase
            .auth()
            .confirmPasswordReset(code, newPassword),
    };
};
