/* eslint-disable no-nested-ternary */

import { Router } from 'preact-router';
import { withTranslation } from 'react-i18next';
import { useParams } from '../../hooks/params';
import { useAuth } from '../../hooks/auth';
import Layout from '../Layout';
import Footer from '../Footer';
import AlreadyAccount from '../../routes/alreadyAccount';
import Login from '../../routes/login';
import Register from '../../routes/register';
import PasswordChange from '../../routes/passwordChange';
import Recovery from '../../routes/recovery';
import Profile from '../../routes/profile';
import Webview from '../../routes/webview';
import Logout from '../../routes/logout';
import Legal from '../../routes/legal';
import { Spinner } from '../Spinner';
import style from './style.module.css';
import Auth from '../Auth';
import { useUser } from '../../contexts/UserContext';

const App = ({ t }) => {
    const { user } = useUser();
    const { loading } = useAuth(t);
    const { params, error } = useParams(t);

    return (
        <div className={style.app}>
            <Layout
                color={params.color}
                logoUrl={params.logo}
                companyName={params.companyName}
                backgroundUrl={params.backgroundImage}
            >
                {error ? (
                    <div style={{ marginTop: '2.2rem' }}>{error}</div>
                ) : loading ? (
                    <Spinner loading={loading} />
                ) : (
                    <Router>
                        {!user && <Login default />}
                        {!user && <PasswordChange path="/password-change" />}
                        {!user && <Recovery path="/recovery" />}
                        {!user && <Register path="/register" />}
                        {!user && <AlreadyAccount path="/already-account" />}
                        {!user && <Webview path="/webview" />}
                        {user && <Legal path="/legal" user={user} />}
                        {user && <Profile default user={user} />}
                        <Logout path="/logout" />
                        <Auth path="/auth" />
                    </Router>
                )}
            </Layout>
            <Footer />
        </div>
    );
};

export default withTranslation()(App);
