const config = {
    version: process.env.VERSION,
    firebase: {
        FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
        FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
        FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL,
        FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
        FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
        FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
    },
    api: {
        url: process.env.API_URL,
    },
    url: {
        URL_IMAGE: process.env.URL_IMAGE,
        URL_LEGAL: process.env.URL_LEGAL,
        URL_CGU_FR: 'https://legal.ugv.skeepers.io/GCU/fr/',
        URL_CGU_ES: 'https://skeepers.io/es/terminos-y-condiciones-generales-del-servicios-grupo-skeepers/',
        URL_CGU_IT: 'https://skeepers.io/it/termini-generali-di-servizio-gruppo-skeepers/',
        URL_CGU_DE: 'https://skeepers.io/de/nutzungsbedingungen/',
        URL_CGU_PT: 'https://skeepers.io/pt/termos-do-servico-grupo-skeepers/',
        URL_CGU_EN: 'https://skeepers.io/en/terms-and-conditions-of-services-skeepers-group/',
        URL_PP_FR: 'https://legal.ugv.skeepers.io/PP/fr/',
        URL_PP_ES: 'https://skeepers.io/es/politica-privacidad-cookies/',
        URL_PP_IT: 'https://skeepers.io/it/informativa-privacy-cookie/',
        URL_PP_DE: 'https://skeepers.io/de/datenschutzrichtlinie/',
        URL_PP_PT: 'https://skeepers.io/pt/politica-privacidade-cookies/',
        URL_PP_EN: 'https://skeepers.io/en/privacy-cookie-policy/',
        URL_LD_FR: 'https://skeepers.io/fr/mentions-legales/',
        URL_LD_ES: 'https://skeepers.io/es/informacion-legal/',
        URL_LD_IT: 'https://skeepers.io/it/menzioni-legali-it/',
        URL_LD_DE: 'https://skeepers.io/de/rechtliche-hinweise/',
        URL_LD_PT: 'https://skeepers.io/pt/mencoes-legais-pt/',
        URL_LD_EN: 'https://skeepers.io/en/legal-notices-en/',
    },
    default: {
        DEFAULT_BACKGROUND: 'https://storage.googleapis.com/upload-teester-com/4009dc28-61dd-4641-b60b-1dbc20b5f596/backoffice/background.jpg',
    },
    ALLOWED_DOMAINS: null, // process.env.ALLOWED_DOMAINS,
};

export default config;
