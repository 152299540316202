import { route } from 'preact-router';
import { useCallback } from 'preact/hooks';
import { useParams } from './params';

export const useRoute = (t) => {
    const { params: searchParams } = useParams(t);

    const redirect = useCallback(
        (to, params = {}) => {
            const searchParamsFiltered = Object.entries(searchParams).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            const p = new URLSearchParams({
                ...searchParamsFiltered,
                ...params,
            });
            route(`${to}?${p.toString()}`);
        },
        [searchParams],
    );

    return redirect;
};
