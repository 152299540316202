import style from './style.module.css';

const sizes = {
    SMALL: 'inherit',
    MEDIUM: '26vh',
    LARGE: '52vh',
};

export const Spinner = ({ loading, height, size }) => {
    const sizeLoading = size !== 'SMALL' ? 4 : 2;
    const sizeHeight = sizes[height] || sizes.LARGE;

    return (
        <div>
            {loading && (
                <div className={style.containerSpinner} style={{ height: sizeHeight }}>
                    <div className={style.spinner} style={{ width: `${sizeLoading}0px`, height: `${sizeLoading}0px`, borderSize: `${sizeLoading}px` }} />
                </div>
            )}
        </div>
    );
};
