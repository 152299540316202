/* eslint-disable max-classes-per-file */

class Provider {
    constructor (name) {
        this.name = name;
    }

    // https://firebase.google.com/docs/reference/js/firebase.User#properties_1
    extractDetails ({ user }) {
        return {
            displayName: user.displayName,
            email: user.email,
            phone: user.phoneNumber,
            picture: user.photoURL,
        };
    }
}

class GoogleProvider extends Provider {
    constructor () {
        super('google');
    }

    extractDetails (info) {
        const { additionalUserInfo, user } = info;

        return {
            ...super.extractDetails(info),
            firstName: additionalUserInfo.profile.given_name,
            lastName: additionalUserInfo.profile.family_name,
            language: additionalUserInfo.profile.locale,
            picture: additionalUserInfo?.profile?.picture ?? user.photoURL,
        };
    }
}

class FacebookProvider extends Provider {
    constructor () {
        super('facebook');
    }

    extractDetails (info) {
        const { additionalUserInfo, user } = info;

        return {
            ...super.extractDetails(info),
            firstName: additionalUserInfo.profile.first_name,
            lastName: additionalUserInfo.profile.last_name,
            picture: additionalUserInfo?.profile?.picture?.data?.url ?? user.photoURL,
        };
    }
}

class EmailProvider extends Provider {
    constructor () {
        super('email');
    }
}

class AppleProvider extends Provider {
    constructor () {
        super('apple');
    }
}

const providers = new Map([
    ['google.com', new GoogleProvider()],
    ['facebook.com', new FacebookProvider()],
    ['apple.com', new AppleProvider()],
    ['password', new EmailProvider()],
]);

/**
 * Normalizes provider names
 */
export const getProvider = (providerId) => providers.get(providerId) || null;
