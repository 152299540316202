import style from './style.module.css';

const Button = ({
    text = '',
    className = '',
    inverse = false,
    disabled = false,
    onClick = () => {},
}) => {
    const buttonStyle = {
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: disabled ? 'var(--color-grey)' : inverse ? 'transparent' : 'var(--color-primary)',
        borderColor: disabled ? 'var(--color-grey)' : 'var(--color-primary)',
        cursor: disabled ? 'not-allowed' : 'pointer',
        color: inverse ? 'var(--color-primary)' : '',
    };

    return (
        <div>
            <button
                type="submit"
                onClick={onClick}
                className={`${className} ${style.button}`}
                style={buttonStyle}
                disabled={disabled}
            >
                {text}
            </button>
        </div>
    );
};

export default Button;
