import { useEffect, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/auth';
import { useParams } from '../../hooks/params';
import { Spinner } from '../Spinner';
import { GRAPHQL_ERRORS, parseGraphqlError } from '../../utils/errors';
import Button from '../FormButton';

import style from './style.module.css';

const Auth = () => {
    const { t } = useTranslation();
    const { loginExternal, loading } = useAuth(t);
    const { params } = useParams(t);
    const [error, setError] = useState();
    const [showButton, setShowButton] = useState(false);

    const goBack = () => window.history.back();

    useEffect(() => {
        const tryLogin = async () => {
            const result = await loginExternal();
            if (!result || result.error) {
                setError(parseGraphqlError(t, result?.error));
                setShowButton(result?.error?.extensions?.code === GRAPHQL_ERRORS.clientToken && window.history.length > 1);
            }
        };

        if (params.clientId && params.authorizationCode) {
            tryLogin();
        } else {
            setError('Invalid parameters clientId and authorizationCode are required');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.authorizationCode, params.clientId, params.redirectUri]);

    return <div className={style.container}>
        {loading && <Spinner loading={loading} />}
        {error && <div className={style.errorContainer}>{error}</div> }
        {showButton && <Button text={t('Go Back')} onClick={goBack} />}
    </div>;
};

export default Auth;
