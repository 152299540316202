import { useCallback } from 'preact/hooks';

import config from '../config';

const eventAlreadyTracked = [];

export const useTracking = (solutionId = '') => {
    const track = useCallback(
        async (eventName) => {
            if (!eventAlreadyTracked.includes(eventName)) {
                eventAlreadyTracked.push(eventName);
                const result = await fetch(`${config.api.url}data/collect?anonymous=1`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        event: eventName,
                        solution_id: solutionId,
                    }),
                });

                if (result.error) {
                    throw new Error(result.error);
                }

                return result.json();
            }
        },
        [solutionId],
    );

    return track;
};
