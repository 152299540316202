import { withTranslation } from 'react-i18next';
import { getLanguageCode } from '../../i18n';
import config from '../../config';
import style from './style.module.css';

const Footer = ({ t }) => {
    const browserLanguage = getLanguageCode().split('-')[0];
    const getLanguage = (language) => (['fr', 'es', 'it', 'de', 'pt'].includes(language) ? language.toUpperCase() : 'EN');

    return (
        <footer className={style.footer}>
            <nav className={style.navBar}>
                <a target="_blank" rel="noreferrer" href={t('FOOTER_POWERED_BY_URL')}>
                    {t('FOOTER_POWERED_BY')}
                </a>
                <a target="_blank" rel="noreferrer" href={config.url[`URL_LD_${getLanguage(browserLanguage)}`]}>
                    {t('FOOTER_LEGAL')}
                </a>
                <a target="_blank" rel="noreferrer" href={config.url[`URL_CGU_${getLanguage(browserLanguage)}`]}>
                    {t('FOOTER_TOS')}
                </a>
                <a target="_blank" rel="noreferrer" href={config.url[`URL_PP_${getLanguage(browserLanguage)}`]}>
                    {t('FOOTER_PRIVACY')}
                </a>
            </nav>
        </footer>
    );
};

export default withTranslation()(Footer);
