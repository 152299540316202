import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import config from '../../config';
import style from './style.module.css';

import { getImageFormat } from '../../utils';

const Layout = ({
    children,
    backgroundUrl = '',
    logoUrl = '',
    companyName = '',
    color = '',
    t,
}) => {
    // Need to pass a const to render translation
    const title = t('TITLE_PAGE');
    return (
        <div
            className={style.page}
            style={{
                'background-image': `url('${
                    config.url.URL_IMAGE
                }?url=${backgroundUrl}&blur=32&w=1920&h=1080&quality=70&output=${getImageFormat()}')`,
            }}
        >
            <div
                className={style.layout}
                style={{
                    '--color-primary': color,
                }}
            >
                <Helmet>
                    <title>
                        {companyName != null ? `${companyName} - ` : ''}
                        {title}
                    </title>
                    <link rel="shortcut icon" href="/assets/favicon.ico" />
                </Helmet>
                <div className={style.header}>
                    {logoUrl != null && <img src={`${
                        config.url.URL_IMAGE
                    }?url=${logoUrl}&w=280&h=80&quality=70&output=${getImageFormat()}`} alt="" />}
                    {companyName != null && (
                        <p>
                            {t('WELCOME_LAYOUT_TITLE', { companyName })}
                        </p>
                    )}
                </div>
                <div className={style.panel}>{children}</div>
            </div>
        </div>
    );
};

export default withTranslation()(Layout);
