let webp = null;

const canUseWebP = () => {
    const elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    return false;
};

export const getImageFormat = () => {
    if (webp === null) {
        webp = canUseWebP();
    }
    return webp ? 'webp' : 'png';
};

export const isMobileDevice = typeof window.orientation !== 'undefined'
    || navigator.userAgent.indexOf('IEMobile') !== -1;

export const isWebview = (userAgent) => /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/gi.test(userAgent);

/**
 * Remove undefined or null values from an object
 */
export const cleanObject = (obj) => Object.entries(obj)
    .filter(([, value]) => value !== null && value !== undefined)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const shouldSignDocument = (user, redirect, redirectUri) => {
    if (user.signableDocuments.length > 0) {
        redirect('/legal');
    } else {
        window.location = redirectUri;
    }
};

export const validate = (state, type, t) => {
    const isType = (...checkTypes) => [...checkTypes, 'ALL'].includes(type);
    const { errors } = state;

    if (isType('EMAIL')) {
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email?.toLowerCase())) {
            errors.email = t('LOGIN_INVALID_EMAIL');
        } else {
            delete errors.email;
        }
    }

    if (isType('DISPLAYNAME')) {
        if (state.displayName === '') {
            errors.displayName = t('LOGIN_INCORRECT_DISPLAYNAME');
        } else {
            delete errors.displayName;
        }
    }

    if (isType('PASSWORD', 'CONFIRMPASSWORD')) {
        delete errors.password;
        delete errors.confirmPassword;

        if (state.password === '') {
            errors.password = t('LOGIN_INCORRECT_PASSWORD');
        } else if (state.confirmPassword === '') {
            errors.confirmPassword = t('LOGIN_INCORRECT_PASSWORD');
        } else if (state.password !== state.confirmPassword) {
            errors.confirmPassword = t('LOGIN_DIFFERENT_PASSWORD');
        }
    }

    return errors;
};

export const CLIENT_NAMES = {
    toky_woky: 'TokyWoky',
};
