import { createContext, useContext, useState } from 'react';

const defaultState = { user: null, setUser: () => { } };

export const UserContext = createContext({
    ...defaultState,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
