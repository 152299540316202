import { render } from 'preact';
import { ApolloProvider } from '@apollo/client';
import firebase from 'firebase/app';
import 'firebase/auth';

import App from './components/App';
import { init as i18n } from './i18n';
import { client } from './apollo';
import config from './config';
import { UserProvider } from './contexts/UserContext';

/**
 * Initialize i18next
 */
const initI18n = i18n;

/**
 * Initialize Firebase
 */
const initFirebase = () => {
    try {
        if (firebase.apps.length === 0) {
            firebase.initializeApp({
                apiKey: config.firebase.FIREBASE_API_KEY,
                authDomain: config.firebase.FIREBASE_AUTH_DOMAIN,
                databaseURL: config.firebase.FIREBASE_DATABASE_URL,
                projectId: config.firebase.FIREBASE_PROJECT_ID,
                storageBucket: config.firebase.FIREBASE_STORAGE_BUCKET,
                messagingSenderId: config.firebase.FIREBASE_MESSAGING_SENDER_ID,
            });
        }
    } catch (err) {
        console.error('Error init firebase:', err);
    }
};

/**
 * Render app once all init functions have run, regardless of their success
 */
Promise.allSettled([initI18n(), initFirebase()]).then(() => render(
    <ApolloProvider client={client}>
        <UserProvider>
            <App />
        </UserProvider>
    </ApolloProvider>,
    document.body,
));
